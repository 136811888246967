import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What's up with year 2021?`}</h1>
    <p>{`I know that there are 2 more months in this year and writing a post-mortem about this year around this time is probably not the best. However with the remaining 2 months I know where things are gonna go. I will call the year 2021 the wasted year because I literally wasted this year learning absolutely nothing or just learning the surface level of many things. When I learn I really wanted to learn things in depth, maybe finish a book or two, maybe create an app. However the only app I created this year is the NFT generation app and its website. Which honestly not satisfied my personal expectations of me to myself.`}</p>
    <p>{`To recap that happened in this year`}</p>
    <ul>
      <li parentName="ul">{`Febuary 2021 - Resigned at the job at (AU Real Estate) to enter (FJPH). Then resigned after a month. Totally learned nothing in (FJPH)`}</li>
      <li parentName="ul">{`June 2021 - Started job at (PCCC) maybe I've learned something but not a whole lot. Specially everything I do here is just basic Java. No, i've learned absolutely nothing, I don't know what those hibernate stuff are. `}</li>
      <li parentName="ul">{`September 2021 - Started dabbling with Web3, done absolutely nothing, but hey I now know Solidity but I prefer having written a working app because the blockchain is a very different paradigm on what I was used to. For example you cannot do batch jobs in the blockchain because that would cost you real money. Also started investing in De-Fi`}</li>
    </ul>
    <p>{`And here we are now, absolutely nothing learned and done. `}</p>
    <p>{`If my goal is to make my previous year of myself look dumb (Hello there MPMD) then I should have at least done something really good. Maybe start up something, but nothing notable happened in the brain side.`}</p>
    <p>{`The most significant learning I had this year is things with regards to De-Fi. I've learned how much should I invest in if I want to retire early. (I just checked it, I need 12 years ASSUMING that the value of bitcoin, ethereum and polygon will remain the same in 12 years) and if things go well for my interview tommorow and I decided to accept it. My time to retirement will be lessened by 4 years! Amazing!`}</p>
    <h2>{`So what's the plan now?`}</h2>
    <p>{`I have tried a lot of things to increase productivity, I have tried gamifying it, through an app, I've tried numerous stuff. What I haven't tried yet is the "I did this thing and now I reward myself" system as mentioned by Kiryuu Coco from a certain collab with Rushia. How about this, I watch a 1 hour tutorial about something and now I am entitled to a nice juicy chicken leg or maybe some takoyaki or something like that. Do this for a whole week and now you're entitled to your chicken wing fridays.`}</p>
    <p>{`Something that might also help to schedule the tutorials I'm about to watch, only 1 hour a day no more no less. from 9-10 PM I should watch this something, if I play dota I am not entitled to my chicken leg anymore.`}</p>
    <p>{`One of the things I've also noticed is I'm almost always in no mood to do anything productive. I should be able to manipulate my mood in some way. I have done that through music, but have I done that through Moe anime yet? Not really. The goal here is to help myself be in the best mood everyday from this day onward and I should research the ways in which I can do such. I might even consider taking some form of a drug.`}</p>
    <p>{`I think I should combine all that I know to make the next year a success.`}</p>
    <p>{`I will get started in improving this blog of mine. I'll start with gatsby js.`}</p>
    <h2>{`Moving Forward`}</h2>
    <p>{`As I have mentioned, my goal for the next year is to make my 2021 version of myself look dump in comparison. By next year I should already own an app that makes passive income (Maybe a blockchain app, maybe even some hyper casual puzzle games). Perhaps next year is when I will take bigger responsibilities on my job, as long as I don't have to go overtly overtime there. `}</p>
    <p>{`By the way I also had 3 instances (probably) where I could have been rich if I invested my money as soon as I can, but I wasn't able to because I was thinking of other things to do. Also try not to be the person you are not. It's nice to have extroverted skillset but don't do anything that requires extroverted skillset. IE seling stuff (hello selling of T-shirts)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      